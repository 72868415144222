import React from 'react'
import { graphql } from 'gatsby'
import CabaretLayout from '../../components/cabaretlayout'
import { StaticImage } from 'gatsby-plugin-image'
import CabaretSponsors from '../../slices/CabaretSponsors'
import CabaretInfosNavigation from '../../components/Infos/CabaretInfos/CabaretInfosNavigations'

// TODO
//
// Background fadeout
const Cabaret = ({ data }) => (
    <CabaretLayout>

        <div className="cabaret-home w-[1920] max-w-[100%] justify-center mx-auto">

            {/* HEADER */}
            <center>
                <div className="top-image text-center w-full px-8">
                    <a href="/cabaret/infos/gagnants#info_content">
                        <StaticImage alt='Cabaret Festif! - 14e édition' className="max-w-[2300px] max-h-[900px]" src="../../images/cabaret/header.png" />
                    </a>
                </div>
            </center>

            {/* MID-PAGE SECTION */}
            <div className='cabaret-home-mid-page-section'>

                {/* FIRST ROW */}
                <div className='cabaret-home-mid-page-section1'>
                    <div className='cabaret-home-mid-page-section1-text'>
                        <h1 className='text-sm'>La 14e édition<br/>du Cabaret Festif!</h1>
                        <h3>Le Cabaret Festif!, volet concours du Festif! de Baie-Saint-Paul, est né en 2011, suite à la deuxième édition du festival. Croyant fermement en la diffusion de la musique nouvelle en région et prônant une offre culturelle diversifiée à l’année longue, l’équipe du Festif ! s’est inspirée des plus grands concours musicaux pour en créer un à l’image de son public curieux et fidèle : les mélomanes aguerri·es de Charlevoix.<br/><br/>Depuis, le Cabaret Festif! a su se positionner comme un tremplin pour les auteur·es-compositeur·es-interprètes désireux·ses de se faire connaître par un public passionné, avide de nouveautés et en quête de talents cachés. Se voulant une plateforme de choix à la nouvelle génération musicale, celle-ci marquée par sa diversité et son esprit créatif, le Cabaret Festif! agit comme un véritable acteur d’impact pour les artistes émergent·es qui ont le potentiel de percer sur la scène alternative québécoise.</h3>
                    </div>
                    <div className='cabaret-home-mid-page-section1-image'>
                        <StaticImage alt='Publication instagram' className="max-w-[1856px] max-h-[1044px]" src="../../images/cabaret/home-mid-page-image1.jpg" />
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className='cabaret-home-mid-page-section2 flex flex-col md:flex-row'>
                    <div className='cabaret-home-mid-page-section2-text md:order-2'>
                        <h1>Les avantages</h1>
                        <ul className="list-disc">
                            <li><h3>Le Cabaret Festif! est le premier concours au Québec à rémunérer ses candidat-es et à offrir l’inscription gratuite.</h3></li>
                            <li><h3>Les projets participants courent la chance de remporter des bourses en argent totalisant 15 000$.</h3></li>
                            <li><h3>La grande diversité des prix offerts aux candidat-es constitue un coffre d’outils complet pour le démarrage d’une carrière en musique (formations, séjours de création, matériel sonore, temps de studio, etc).</h3></li>
                            <li><h3>Grâce à nos partenaires diffuseurs, les candidat-es courent la chance de se produire sur les plus grandes scènes du Québec au cours de l’année suivant leur participation.</h3></li>
                            <li><h3>Les 12 projets choisis jouissent d’une visibilité majeure offerte par plusieurs médias de partout dans la province, leur donnant ainsi la chance de se faire connaître bien au-delà du concours.</h3></li>
                        </ul>
                    </div>
                    <div className='cabaret-home-mid-page-section2-image md:order-1'>
                        <StaticImage alt='Publication instagram' className="max-w-[1856px] max-h-[1044px]" src="../../images/cabaret/home-mid-page-image2.png" />
                    </div>
                </div>

            </div>

            <CabaretInfosNavigation noTitle={true} />

            {/* VIDEO */}
            {/* <div>
                <video loop controls autoPlay muted>
                    <source src="https://le-festif-ca.cdn.prismic.io/le-festif-ca/Zjdki0MTzAJOCiiX_LeFestif_CabaretFestif2024.mov" type="video/quicktime" />
                </video>
            </div> */}

            <div className='mt-0 md:mx-36 mx-9 flex justify-center'>
                <CabaretSponsors data={data}/>
            </div>

        </div>
    </CabaretLayout>
)

export default Cabaret

export const cabaretSponsorResult = graphql`
query {
    allPrismicCabaretsponsor {
        edges {
          node {
            data {
              image {
                alt
                gatsbyImageData
              }
              link {
                url
              }
              name {
                text
              }
              category
              priority
            }
          }
        }
      }
    }
`
